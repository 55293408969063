<template>
  <travio-center-container :pageTitle="pageTitle">
    <vx-card>
      <div class="flex flex-wrap items-center mb-base">
        <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
        <div>
          <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
          <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload logo</vs-button>
          <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>
      <div v-if="isEditForm" class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company Id</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input disabled class="w-full" v-model="form.id" />
        </div>
      </div>
       <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Internal Reference</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.internalRef" v-validate="'max:100'" name="Internal Reference" />
          <span class="text-danger text-sm">{{ errors.first('Internal Reference') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.name" v-validate="'required|max:150'" name="Company Name" />
          <span class="text-danger text-sm">{{ errors.first('Company Name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address 1</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.address1" name="Address 1"  v-validate="'max:250'" />
          <span class="text-danger text-sm">{{ errors.first('Address 1') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address 2</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.address2" name="Address 2"  v-validate="'max:250'" />
          <span class="text-danger text-sm">{{ errors.first('Address 2') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>City</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.city" name="City"  v-validate="'max:150'" />
          <span class="text-danger text-sm">{{ errors.first('City') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>County / State</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.state" name="State"  v-validate="'max:150'" />
          <span class="text-danger text-sm">{{ errors.first('State') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Postal Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.postalCode" name="Postal Code"  v-validate="'max:20'" />
          <span class="text-danger text-sm">{{ errors.first('Postal Code') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Country</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select :disabled="readOnly" class="style-chooser" :value="selectedCountry" @input="setSelectedCountry" placeholder="Country" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Email Address</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.emailAddress" name="Email Address" v-validate="'email|max:150'" />
          <span class="text-danger text-sm">{{ errors.first('Email Address') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>ABTA Number</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.abtaNumber" name="ABTA Number"  v-validate="'max:50'" />
          <span class="text-danger text-sm">{{ errors.first('ABTA Number') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>IATA Number</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.iataNumber" name="IATA Number"  v-validate="'max:50'" />
          <span class="text-danger text-sm">{{ errors.first('IATA Number') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Commission Information</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.commissionInformation" name="Commission Information"  v-validate="'max:100'" />
          <span class="text-danger text-sm">{{ errors.first('Commission Information') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Share Orders</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch :disabled="readOnly" v-model="form.shareOrders" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Website Url</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.websiteUrl" name="Website Url"  v-validate="'max:500'" />
          <span class="text-danger text-sm">{{ errors.first('Website Url') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Homepage Url</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.homePageUrl" name="Homepage Url"  v-validate="'max:500'" />
          <span class="text-danger text-sm">{{ errors.first('Homepage Url') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Login Page Url</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.loginPageUrl" name="Login Page Url"  v-validate="'max:500'" />
          <span class="text-danger text-sm">{{ errors.first('Login Page Url') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Results Page Url</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input :disabled="readOnly" class="w-full" v-model="form.resultsPageUrl" name="Results Page Url"  v-validate="'max:500'" />
          <span class="text-danger text-sm">{{ errors.first('Results Page Url') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Map Email Domains to Company</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
            <vs-textarea 
              name="Terms" rows="4" v-model="form.emailDomains" v-validate="'max:1000'" class="w-full mb-2" :disabled="readOnly" />
          <span class="text-sm">Enter domains (one per line). Emails from these domains will be added to this company automatically</span>
          <span class="text-danger text-sm">{{ errors.first('Email Domains') }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Overwrite Company Details</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
          <vs-switch :disabled="readOnly" v-model="form.overwriteCompanyDetails" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
          <vs-switch :disabled="readOnly" v-model="form.isActive" />
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mr-4">Cancel</vs-button>
        <vs-button v-if="userHasPermission('company_settings_edit')" @click='submitCreate'>Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    vSelect
  },
  props: {
    readOnly: { type: Boolean, default: true },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        internalRef: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode: null,
        websiteUrl: '',
        homePageUrl: '',
        loginPageUrl: '',
        resultsPageUrl: '',
        emailDomains: '',
        emailAddress: '',
        abtaNumber: '',
        iataNumber: '',
        commissionInformation: '',
        shareOrders: false,
        isActive: true,
        overwriteCompanyDetails: false,
        logoUrl: null,
      }),
      logoFile: null,
      pageTitle: '',
      countryOptions: [],
      selectedCountry: {},
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    isEditForm() {
      return this.$route.params.companyId > 0 ? true : false;
    },
    editMode () {
      return !this.readOnly && this.$route.params.companyId
    },
    createMode () {
      return !this.readOnly && !this.$route.params.companyId
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  async mounted () {
    
    const formDataPromise = this.$http.get(`api/b2b/companies/formData`)
    this.$vs.loading()
    if (this.isEditForm) {
      this.pageTitle = 'Edit Company'
      const companyDetailsPromise = this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}`)
      const [companyDetailsResponse, formDataResponse] = await Promise.all([companyDetailsPromise, formDataPromise])
      this.form = Object.assign(this.form, companyDetailsResponse.data)
      this.countryOptions = formDataResponse.data.countryCodes
      this.selectedCountry = this.countryOptions.find(x => x.code == companyDetailsResponse.data.countryCode) || {}
    } else {
      this.pageTitle = 'Create New Company'
      let formDataResponse = await formDataPromise
      this.countryOptions = formDataResponse.data.countryCodes
    }
    this.$vs.loading.close()
    
  },
  methods: {
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    async submitCreate () {
      // Async
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      let requestPromise = null
      let companyId = null
      if (!this.isEditForm) {
        requestPromise = this.form.post(`api/b2b/companies/${this.$route.params.applicationId}`)
      } else {
        companyId = this.$route.params.companyId
        requestPromise = this.form.put(`api/b2b/companies/${this.$route.params.applicationId}`)
      }

      this.$vs.loading()
      try {
        const response = await requestPromise
        companyId = this.createMode ? response.data : companyId
        if (this.logoFile) {
          await this.submitLogo(companyId)
        }
        this.$_notifySuccess('Company settings successfully saved');
        this.$router.push({
          name: 'company-list',
          params: { applicationId: this.$route.params.applicationId }
        })
      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }
      this.$vs.loading.close()
    },
    async handleFileInputChange () {
      this.form.logoUrl = URL.createObjectURL(this.logoFile)
    },
    async handleFileUpload () {
      this.logoFile = this.$refs.companyLogoInput.files[0];
      if (this.logoFile && this.createMode) {
        // Temporary render file, can't upload yet since Company ID is not yet available
        this.form.logoUrl = URL.createObjectURL(this.logoFile)
      }
      else if (this.logoFile && this.editMode) {
        this.$vs.loading()
        try {
          await this.submitLogo(this.$route.params.companyId)
          this.$_notifySuccess('Successfully uploaded company logo')
        } catch {
          this.$_notifyFailure('Unable to upload logo')
        }
        this.$vs.loading.close()
      }
    },
    submitLogo (companyId) {
      let formData = new FormData();
      formData.append('logoFile', this.logoFile)
      formData.append('companyId', companyId)
      return new Promise((resolve, reject) => {
        this.$http.post(`api/b2b/companies/${this.$route.params.applicationId}/${companyId}/logos`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        .then(response => {
          this.form.logoUrl = response.data.logoUrl
          resolve(response)
        })
        .catch(error => reject(error))
      })
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
    },
    handleCancel () {
      this.$router.push({
        name: 'company-list',
        params: { applicationId: this.$route.params.applicationId }
      })
    },
  }
}
</script>